import css from "../../css/index/jumbotron.module.css";

import SearchForm from "./SearchForm";
import HeaderIndex from "../header/HeaderIndex";
import Logo from "../header/Logo";
import SlideDownIcon from "../../static/icons/slide-down.svg";

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Link from "next/link";

const Jumbotron = (props) => {
    const { category } = props;

    const color = category === "rent" ? "blue" : "red";
    const logoColor = category === "rent" ? "blue" : null;

    const jumbotronClass = classNames({
        [css.jumbotron]: category === "sale",
        [css.jumbotronBlue]: category === "rent",
    });
    const linkRedClass = classNames({
        [css.linkRedActive]: category === "sale",
        [css.linkRed]: category === "rent",
    });
    const linkBlueClass = classNames({
        [css.linkBlueActive]: category === "rent",
        [css.linkBlue]: category === "sale",
    });

    return (
        <div className={jumbotronClass}>
            <HeaderIndex color={color} />
            <div className={css.inner}>
                <div className={css.logo}>
                    <Logo size="xl" color={logoColor} />
                </div>
                <div className={css.links}>
                    <Link href="/" className={linkRedClass}>
                        For Sale
                    </Link>
                    <Link
                        as="/homes/for_rent"
                        href="/?category=rent"
                        className={linkBlueClass}
                    >
                        For Rent
                    </Link>
                </div>
                <SearchForm category={category} />
            </div>
            <div className={css.slideDown}>
                <SlideDownIcon />
            </div>
        </div>
    );
};

Jumbotron.propTypes = {
    category: PropTypes.string.isRequired,
};

export default Jumbotron;
