import React from "react";
import PropTypes from "prop-types";
import { getAvatarPhotoUrl, WIDTH_THUMBS } from "../../service/photo";

const AvatarImgUrl = "/static/img/avatar.png";

const Avatar = (props) => {
    const { photo, size } = props;

    if (!photo) {
        return <img src={AvatarImgUrl} width={WIDTH_THUMBS[size]} alt="Profile" />;
    }

    return <img src={getAvatarPhotoUrl(photo, size)} alt="Profile" />;
};

Avatar.propTypes = {
    size: PropTypes.string.isRequired,
    photo: PropTypes.string,
};

export default Avatar;
